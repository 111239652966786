import {
    isWithinInterval,
    areIntervalsOverlapping,
    isBefore,
    format,
    addDays,
    subDays,
} from "date-fns"
import { hu } from "date-fns/locale"

export function isValidDateRange(
    fromDate,
    reservedIntervals,
    dateToday,
    dateToCheck,
    moveInDays
) {
    if (
        isBefore(dateToCheck, dateToday) ||
        reservedIntervals.some(interval =>
            isWithinInterval(dateToCheck, interval)
        ) ||
        (fromDate && isBefore(dateToCheck, fromDate))
    ) {
        return false
    }

    if (fromDate) {
        return !(
            reservedIntervals.some(interval =>
                areIntervalsOverlapping(
                    { start: fromDate, end: dateToCheck },
                    interval,
                    { inclusive: true }
                )
            ) ||
            moveInDays.some(day => {
                return isWithinInterval(
                    day,
                    {
                        start: fromDate,
                        end: dateToCheck,
                    },
                    { inclusive: true }
                )
            })
        )
    }

    return true
}

export function createReservedDaysIntervals(reservations) {
    return reservations
        ? reservations.reduce((previousArray, r) => {
              const [_, fromString, toString] = r
              const fromDate = addDays(new Date(fromString), 1)
              const toDate = subDays(new Date(toString), 1)
              if (toDate < fromDate) {
                  return previousArray
              }
              return [
                  ...previousArray,
                  {
                      start: fromDate,
                      end: toDate,
                  },
              ]
          }, [])
        : []
}

export function getMoveInDates(reservations) {
    return reservations
        ? reservations.map(r => {
              const [, fromString] = r
              return new Date(fromString)
          })
        : []
}

export function formatReadableRange(range, locale = hu) {
    return (
        range.to &&
        range.from &&
        `${format(range.from, "Y. MM. dd.", {
            locale,
        })}  -  ${format(range.to, "Y. MM. dd.", { locale })}`
    )
}

export const listEuCountries = {
    hu: {
        AT: "Ausztria",
        BE: "Belgium",
        BG: "Bulgária",
        HR: "Horvátország",
        CY: "Ciprus",
        CZ: "Csehország",
        DK: "Dánia",
        EE: "Észtország",
        FI: "Finnország",
        FR: "Franciaország",
        DE: "Németország",
        GR: "Görögország",
        HU: "Magyarország",
        IE: "Írország",
        IT: "Olaszország",
        LV: "Lettország",
        LT: "Litvánia",
        LU: "Luxembourg",
        MT: "Málta",
        NL: "Hollandia",
        PL: "Lengyelország",
        PT: "Portugália",
        RO: "Románia",
        SK: "Szlovákia",
        SI: "Szlovénia",
        SW: "Svájc",
        ES: "Spanyolország",
        SE: "Svédország",
        GB: "Egyesült Királyság",
    },
    en: {
        AT: "Austria",
        BE: "Belgium",
        BG: "Bulgaria",
        HR: "Croatia",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DK: "Denmark",
        EE: "Estonia",
        FI: "Finland",
        FR: "France",
        DE: "Germany",
        GR: "Greece",
        HU: "Hungary",
        IE: "Ireland",
        IT: "Italy",
        LV: "Latvia",
        LT: "Lithuania",
        LU: "Luxembourg",
        MT: "Malta",
        NL: "Netherlands",
        PL: "Poland",
        PT: "Portugal",
        RO: "Romania",
        SK: "Slovakia",
        SI: "Slovenia",
        SW: "Switzerland",
        ES: "Spain",
        SE: "Sweden",
        GB: "United Kingdom",
    },
}

// reservedRanges: {end: Date, start: Date}, currentMonthData: Date, numberOfDaysInMonth: number
//return number[]
export function getReservedDays(
    reservedRanges,
    currentMonthDate,
    numberOfDaysInMonth
) {
    const days = []
    if (reservedRanges) {
        reservedRanges.forEach(range => {
            const currentMonth = currentMonthDate.getMonth()
            const currentYear = currentMonthDate.getFullYear()

            const isToInCurrentMonth = range.end.getMonth() === currentMonth
            const isFromInCurrentMonth = range.start.getMonth() === currentMonth

            const isToCurrentYear = range.end.getFullYear() === currentYear
            const isFromInCurrentYear =
                range.start.getFullYear() === currentYear

            if (
                (isFromInCurrentMonth && isFromInCurrentYear) ||
                (isToInCurrentMonth && isToCurrentYear)
            ) {
                const lastUnavailableDay =
                    isToInCurrentMonth && isToCurrentYear
                        ? range.end.getDate()
                        : numberOfDaysInMonth

                const firstUnavailableDay =
                    isFromInCurrentMonth && isFromInCurrentYear
                        ? range.start.getDate()
                        : 1

                for (
                    let i = firstUnavailableDay;
                    i <= lastUnavailableDay;
                    i++
                ) {
                    days.push(i)
                }
            }
        })
    }
    return days
}

export function isOnSameDay(date1, date2) {
    const date1Year = date1.getUTCFullYear()
    const date1Month = date1.getUTCMonth()
    const date1Day = date1.getUTCDate()

    const date2Year = date2.getUTCFullYear()
    const date2Month = date2.getUTCMonth()
    const date2Day = date2.getUTCDate()

    return (
        date1Year === date2Year &&
        date2Month === date1Month &&
        date1Day === date2Day
    )
}
