import React from "react"
import { css } from "@emotion/core"
import {
  fontSizes,
  colors,
  desktopMediaQuery,
  sizes,
} from "../../styles/constants"

export function PrimaryButton({ children, color = colors.primary, ...props }) {
  return (
    <BaseButton
      css={css`
        background: ${color};
        color: white;
        border: 0px;
        padding: 0.5rem 1rem;
        ${desktopMediaQuery} {
          letter-spacing: 0.1rem;
          padding: 1rem 4rem;
        }
      `}
      {...props}
    >
      {children}
    </BaseButton>
  )
}

export function SecondaryButton({ children, ...props }) {
  return (
    <BaseButton
      css={css`
        background: white;
        color: ${colors.primary600};
        padding: 0.5rem 1rem;
        ${desktopMediaQuery} {
          letter-spacing: 0.1rem;
          padding: 1rem 4rem;
        }
      `}
      {...props}
    >
      {children}
    </BaseButton>
  )
}

export function SmallButton({ children, ...props }) {
  return (
    <button
      css={css`
        font-size: ${fontSizes.default};
        background-color: ${colors.primary};
        outline: none;
        padding: ${sizes.smallScale.xs} ${sizes.smallScale.m};
        color: ${colors.coldGray};
        border-width: 0;
        &:hover {
          cursor: pointer;
        }
        ${props.disabled &&
          `
                    color: ${colors.primary400} !important;
                    background: ${colors.primary200};
                    &:hover {
                        cursor: auto;
                    }
                `}
      `}
      {...props}
    >
      {children}
    </button>
  )
}

function BaseButton({ children, ...props }) {
  return (
    <button
      css={css`
        font-size: ${fontSizes.medium};
        border: 0.1rem solid ${colors.primary};
        outline: none;
        ${props.disabled &&
          `
                    border-color: ${colors.darkGray} !important;
                    color: ${colors.darkGray} !important;
                    background: ${colors.gray} !important;
                `}
        &:hover {
          cursor: pointer;
        }
      `}
      {...props}
    >
      {children.toUpperCase()}
    </button>
  )
}
