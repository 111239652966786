import React from "react"
import { css } from "@emotion/core"
import SectionTitle from "./SectionTitle"
import InputField from "./InputField"
import CountrySelector from "./CountrySelector"
import { memo } from "react"
import { desktopMediaQuery, sizes } from "../../styles/constants"
import { useContext } from "react"
import { TextContentCtx } from "../../templates/booking-page"

function PersonalInformation({
    onChange,
    onCountryChange,
    selectedCountry,
    requiredDataKeys,
    missingValues,
}) {
    const { contact } = useContext(TextContentCtx)

    const inputKeysAndLabels = [
        { key: "firstname", label: contact.surname },
        { key: "familyname", label: contact.familyName },
        { key: "dateOfBirth", label: contact.dateOfBirth },
        { key: "postcode", label: contact.postcode },
        { key: "city", label: contact.city },
        { key: "street", label: contact.address },
        { key: "door", label: contact.address2 },
        { key: "phone", label: contact.phoneNumber },
        { key: "email", label: contact.email },
    ]

    return (
        <div css={styles.container}>
            <SectionTitle css={styles.title} order={2}>
                {contact.yourInformation}
            </SectionTitle>
            {inputKeysAndLabels.map(keyLabel => (
                <InputField
                    name={keyLabel.key}
                    required={requiredDataKeys.some(
                        k => k.key === keyLabel.key
                    )}
                    onChange={onChange}
                    label={keyLabel.label}
                    css={[styles.input[keyLabel.key], styles.input.base]}
                    key={keyLabel.key}
                    isError={missingValues.some(k => k.key === keyLabel.key)}
                />
            ))}
            <CountrySelector
                selected={selectedCountry}
                onChange={onCountryChange}
                css={[styles.input.country, styles.input.base]}
            />
        </div>
    )
}

export default memo(PersonalInformation)

const styles = {
    container: css`
        padding: ${sizes.smallScale.xl} ${sizes.smallScale.m};
        display: grid;
        width: 100%;
        grid-template-areas:
            "title"
            "firstname"
            "familyname"
            "dateOfBirth"
            "country"
            "postcode"
            "city"
            "street"
            "door"
            "phone"
            "email";
        ${desktopMediaQuery} {
            width: 90%;
            grid-template-areas:
                "title title title"
                "firstname familyname ."
                "dateOfBirth . ."
                "country postcode city"
                "street door ."
                "phone email .";
            padding: ${sizes.smallScale.xl};
        }
    `,
    title: css`
        grid-area: title;
    `,
    input: {
        base: css`
            padding-bottom: ${sizes.smallScale.xl};
            min-width: ${sizes.largeScale.xs};
            width: 100%;
            ${desktopMediaQuery} {
                width: 80%;
            }
        `,
        firstname: css`
            grid-area: firstname;
        `,
        familyname: css`
            grid-area: familyname;
        `,
        dateOfBirth: css`
            grid-area: dateOfBirth;
        `,
        country: css`
            grid-area: country;
        `,
        postcode: css`
            grid-area: postcode;
        `,
        city: css`
            grid-area: city;
        `,
        street: css`
            grid-area: street;
        `,
        door: css`
            grid-area: door;
        `,
        phone: css`
            grid-area: phone;
        `,
        email: css`
            grid-area: email;
        `,
    },
}
