import { css } from "@emotion/core"
import { desktopMediaQuery, colors } from "../../styles/constants"

export default {
    calendar: css`
        padding: 1rem;
        box-shadow: inset 0 0.0625em 0.125em rgba(43, 37, 35, 0.05);
        background: white;
        position: relative;
        & ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
        }
        & li {
            display: block;
            float: left;
            width: 14.342%;
            box-sizing: border-box;
            border: 1px solid #ccc;
            margin-right: -1px;
            margin-bottom: -1px;
        }
        ${desktopMediaQuery} {
            padding: 2rem;
            min-height: 32rem;
            margin-bottom: 2rem;
            border-radius: 4px;
        }
    `,
    days: css`
        & li {
            height: 55px;
        }
    `,
    date: css`
        text-align: center;
        margin-bottom: 5px;
        padding: 4px;
        width: 40px;
        float: right;
        display: flex;
        font-weight: bold;
    `,
    otherDay: css`
        background: #fff;
    `,
    weekdays: css`
        height: 40px;
        & li {
            text-align: center;
            text-transform: uppercase;
            line-height: 20px;
            border: none !important;
            padding: 10px 6px;
            font-size: 13px;
        }
    `,
    selectedDay: css`
        background-color: ${colors.primary};
        z-index: 100;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        position: absolute;
        top: -5%;
        left: -5%;
        width: 110%;
        height: 110%;
    `,
    includedDay: css`
        background-color: ${colors.primary300};
        box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4),
            inset 0 -7px 9px -7px rgba(0, 0, 0, 0.4);
    `,
    topLine: css`
        ${desktopMediaQuery} {
            position: absolute;
            top: 0;
            left: 0;
            background: ${colors.primary};
            height: 0.5rem;
            width: 100%;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
    `,
    halfDay: css`
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: ${colors.gray};
    `,
}
