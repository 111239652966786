import React, { Fragment } from "react"
import { css } from "@emotion/core"
import {
  colors,
  fontSizes,
  desktopMediaQuery,
  sizes,
  containerQuery,
} from "../../styles/constants"
import { thousandSpacing } from "../../utils/string"
import { graphql, useStaticQuery } from "gatsby"
import { useContext } from "react"
import { TextContentCtx } from "../../templates/booking-page"
import Icons, { icon } from "../Icons"
import { LanguageCtx, defaultLang } from "../../context"

function PriceInfoBar({ className }) {
  const textContent = useContext(TextContentCtx)
  const { lang } = useContext(LanguageCtx)
  const data = useStaticQuery(graphql`
    query PriceInfo {
      allFile(filter: { sourceInstanceName: { eq: "data" } }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                basePrice
                perPersonPrice
                mobileNumber
                emailAddress
                couplePrice
              }
            }
          }
        }
      }
    }
  `)

  const {
    basePrice,
    perPersonPrice,
    mobileNumber,
    emailAddress,
    couplePrice,
  } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <div css={styles.container} className={className}>
      <div css={styles.title}>{textContent.info.pricesTitle}</div>
      <span css={styles.priceSupportText}>
        {textContent.info.priceMainInfo}
      </span>
      <PersonNightPrice
        price={basePrice}
        unit={textContent.info.basePriceUnit}
      />
      <span css={styles.priceSupportText}>
        {(lang === defaultLang && (
          <Fragment>
            <b>2 fő</b> esetén
          </Fragment>
        )) || (
          <Fragment>
            In case of <b>2 persons</b>
          </Fragment>
        )}
      </span>
      <PersonNightPrice
        price={couplePrice}
        unit={textContent.info.basePriceUnit}
      />
      {perPersonPrice !== 0 && (
        <Fragment>
          <span css={styles.priceSupportText}>
            {(lang === defaultLang && (
              <Fragment>
                <b>4 fő</b> felett az extra személyeknek
              </Fragment>
            )) || (
              <Fragment>
                Above <b>4 persons</b> extra fee
              </Fragment>
            )}
          </span>
          <PersonNightPrice
            price={perPersonPrice}
            unit={textContent.info.perPersonUnit}
            css={css`
              padding-bottom: ${sizes.smallScale.m};
            `}
          />
        </Fragment>
      )}
      <ul css={styles.possibilitiesList}>
        {textContent.info.possibilities?.map(possibility => (
          <li key={possibility} css={styles.possListItem}>
            <Icons
              type={icon.CHECK_MARK}
              color={colors.coldGray}
              css={css`
                padding-right: 1rem;
              `}
            />
            <span>{possibility}</span>
          </li>
        ))}
      </ul>
      <div
        css={css`
          font-weight: bold;
        `}
      >
        {textContent.info.question}
      </div>
      <div>{mobileNumber}</div>
      <div css={styles.email}>{emailAddress}</div>
    </div>
  )
}

export default PriceInfoBar

const styles = {
  container: css`
    width: 25%;
    min-height: 100%;
    background: ${colors.primary};
    color: ${colors.tertary};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 1rem;
    & * {
      color: ${colors.coldGray};
    }
    ${desktopMediaQuery} {
      padding: 2rem;
    }
  `,
  title: css`
    font-size: ${fontSizes.title.h4};
    padding-bottom: 1rem;
  `,
  priceSupportText: css`
    font-size: ${fontSizes.default};
  `,
  info: css`
    font-style: italic;
    color: ${colors.secondary} !important;
  `,
  possibilitiesList: css`
    padding: 3rem 0 3rem 0;
    font-size: ${fontSizes.default};
    & li {
      padding-top: 1rem;
    }
  `,
  possListItem: css`
    display: flex;
    align-items: center;
  `,
  email: css`
    word-wrap: break-word;
    ${containerQuery.stage3} {
      word-wrap: unset;
    }
  `,
}

function PersonNightPrice({ price, unit, className }) {
  return (
    <div
      css={css`
        display: flex;
        align-items: baseline;
        padding-bottom: 1.5rem;
        flex-wrap: wrap;
        & span {
          padding-right: 0.5rem;
        }
      `}
      className={className}
    >
      <span
        css={css`
          font-size: ${fontSizes.xLarge};
        `}
      >
        {thousandSpacing(price)}
      </span>
      <span
        css={css`
          font-weight: bold;
          font-size: ${fontSizes.medium};
        `}
      >
        Ft
      </span>
      <span
        css={css`
          height: 100%;
          align-self: center;
        `}
      >
        {`\t${unit}`}
      </span>
    </div>
  )
}
