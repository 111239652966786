import React from "react"
import { format } from "date-fns"
import { css } from "@emotion/core"
import { desktopMediaQuery, fontSizes, colors } from "../../../styles/constants"
import { IconButton, ICONS } from "../../Button/IconButton"

function MonthStepper({ onDateChange, currentDate, locale }) {
  const handleNextClick = () => {
    onDateChange(currentDate.year, currentDate.month + 1)
  }
  const handleBackClick = () => {
    onDateChange(currentDate.year, currentDate.month - 1)
  }
  const date = new Date(currentDate.year, currentDate.month, currentDate.day)
  const nextDate = new Date(
    currentDate.year,
    currentDate.month + 1,
    currentDate.day
  )
  const localDate = new Date()
  return (
    <div
      css={css`
        ${styles.container};
      `}
    >
      <IconButton
        iconName={ICONS.PREV_ARROW}
        onClick={handleBackClick}
        disabled={
          localDate.getFullYear() === date.getFullYear() &&
          localDate.getMonth() === date.getMonth()
        }
        css={styles.prevArrow}
      />
      <span css={styles.dateCurrent}>
        <h3
          className="title"
          css={styles.monthName}
          data-testid="calendar_month_current_title"
        >
          {format(date, "LLLL", { locale })} {date.getFullYear()}
        </h3>
        {/* HAve to do this, becasue apparetly fromat has a bug on 2021
                January it shows 2020 */}
      </span>
      <span css={styles.dateNext}>
        <h3
          className="title"
          css={styles.monthName}
          data-testid="calendar_month_next_title"
        >
          {format(nextDate, "LLLL", { locale })} {nextDate.getFullYear()}
        </h3>
        {/* HAve to do this, becasue apparetly fromat has a bug on 2021
                January it shows 2020 */}
      </span>
      <IconButton
        iconName={ICONS.NEXT_ARROW}
        onClick={handleNextClick}
        css={styles.nextArrow}
      />
    </div>
  )
}

export default MonthStepper

const styles = {
  container: css``,
  dateCurrent: css`
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    ${desktopMediaQuery} {
      left: 25%;
    }
  `,
  dateNext: css`
    display: none;
    position: absolute;
    transform: translate(-50%);
    left: 75%;
    ${desktopMediaQuery} {
      display: block;
    }
  `,
  monthName: css`
    display: inline-block;
    font-size: ${fontSizes.default};
    margin: 0;
    padding-right: 1rem;
    color: ${colors.primary800};
    ${desktopMediaQuery} {
      font-size: ${fontSizes.title.default};
    }
  `,
  prevArrow: css`
    float: left;
    padding: 0;
  `,
  nextArrow: css`
    float: right;
    padding: 0;
  `,
}
