import React from "react"
import { css } from "@emotion/core"
import {
    colors,
    desktopMediaQuery,
    fontSizes,
    sizes,
} from "../../styles/constants"

function SectionTitle({ children, order, ...styleProps }) {
    return (
        <div css={styles.container} {...styleProps}>
            <div css={styles.oderContainer}>
                <div css={styles.order}>{order}</div>
            </div>
            <span css={styles.title}>{children}</span>
        </div>
    )
}

export default SectionTitle

const styles = {
    container: css`
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    `,
    oderContainer: css`
        min-width: ${sizes.smallScale.xxl};
    `,
    order: css`
        width: 34px;
        color: ${colors.primary};
        line-height: 1.8rem;
        border-radius: 50%;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        border: 3px solid ${colors.primary};
        margin-right: 1rem;
    `,
    title: css`
        font-size: ${fontSizes.large};
        ${desktopMediaQuery} {
            font-size: ${fontSizes.xLarge};
        }
    `,
}
