import React, { Fragment } from "react"
import { useState } from "react"
import { css } from "@emotion/core"
import { colors } from "../../styles/constants"
import downArrowIcon from "../../images/down_arrow.svg"
import { listEuCountries } from "../../utils/date"
import { useContext } from "react"
import { TextContentCtx } from "../../templates/booking-page"
import { LanguageCtx } from "../../context"

function CountrySelector({ onChange, className, selected }) {
    const {
        contact: { country },
    } = useContext(TextContentCtx)

    const { lang } = useContext(LanguageCtx)

    const [ddOpen, setDdOpen] = useState(false)

    const handleDdOpen = () => {
        setDdOpen(!ddOpen)
    }

    const selectCountry = countryName => () => {
        onChange(countryName)
    }

    return (
        <Fragment>
            <input name="country" id="country" type="hidden" />
            <div
                className={`field ${className}`}
                css={css`
                    & * {
                        width: 100%;
                    }
                `}
            >
                <label className="label">
                    {country}
                    <span
                        css={css`
                            color: ${colors.red400};
                        `}
                    >
                        *
                    </span>
                </label>
                <div
                    className={`dropdown ${ddOpen ? "is-active" : ""}`}
                    onClick={handleDdOpen}
                >
                    <div className="dropdown-trigger">
                        <button
                            className="button"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu"
                            css={styles.ddButton}
                        >
                            <span>{selected}</span>
                            <span className="icon is-small">
                                <img
                                    src={downArrowIcon}
                                    alt="down arrow"
                                    css={css`
                                        margin-bottom: 0;
                                    `}
                                />
                            </span>
                        </button>
                    </div>
                    <div
                        className="dropdown-menu"
                        id="dropdown-menu"
                        role="menu"
                    >
                        <div className="dropdown-content" css={styles.ddBody}>
                            {Object.keys(listEuCountries[lang]).map(
                                countryCode => (
                                    <a
                                        className="dropdown-item"
                                        key={countryCode}
                                        onClick={selectCountry(
                                            listEuCountries[lang][countryCode]
                                        )}
                                    >
                                        {listEuCountries[lang][countryCode]}
                                    </a>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CountrySelector

const styles = {
    ddButton: css`
        border: 0px;
        background: ${colors.coldGray};
        height: 3rem;
        display: flex;
        justify-content: space-between;
    `,
    ddBody: css`
        background: ${colors.coldGray};
        max-height: 11rem;
        overflow: scroll;
    `,
}
