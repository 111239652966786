import React, {
    useState,
    useEffect,
    useMemo,
    Fragment,
    useContext,
} from "react"
import styles from "./styles"
import { hu, enGB } from "date-fns/locale"
import { createReservedDaysIntervals, getMoveInDates } from "../../utils/date"
import ErrorMsg from "../ErrorMsg"
import Spinner from "../Spinner"
import { centerContent, colors } from "../../styles/constants"
import { css } from "@emotion/core"
import SelectorCalendar from "./components/SelectorCalendar"
import { TextContentCtx } from "../../templates/booking-page"
import { LanguageCtx, defaultLang } from "../../context"
import { useRanges } from "../../services/hooks"

function Calendar({
    onRangeSelected,
    containerCss,
    dayTypeText: { freeDays, onlyMoveOut, reservedDays },
}) {
    const { errorTitle, calendarLoadingError } = useContext(TextContentCtx)

    const { data: reservations, isLoading, isError } = useRanges()

    const [locale, setLocale] = useState(hu)

    const { lang } = useContext(LanguageCtx)

    useEffect(() => {
        setLocale(lang && lang === defaultLang ? hu : enGB)
    }, [lang])

    const reservationIntervals = useMemo(
        () => createReservedDaysIntervals(reservations),
        [reservations]
    )

    const moveInDays = useMemo(() => getMoveInDates(reservations), [
        reservations,
    ])

    return (
        <div
            className="container"
            css={css`
                ${styles.calendar};
                ${containerCss};
            `}
            data-testid="calendar"
        >
            {((isLoading || isError) && (
                <div css={centerContent}>
                    {(isLoading && <Spinner />) || (
                        <ErrorMsg
                            header={errorTitle}
                            body={calendarLoadingError}
                        />
                    )}
                </div>
            )) || (
                <Fragment>
                    <div css={styles.topLine} />
                    <SelectorCalendar
                        locale={locale}
                        onRangeSelected={onRangeSelected}
                        reservations={reservations}
                        reservationIntervals={reservationIntervals}
                        moveInDays={moveInDays}
                    />
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                        `}
                    >
                        <span
                            css={css`
                                padding-right: 2rem;
                            `}
                        >
                            <span
                                css={css`
                                    font-weight: bold;
                                `}
                            >
                                00
                            </span>
                            <span>{` - ${freeDays}`}</span>
                        </span>
                        <span
                            css={css`
                                padding-right: 2rem;
                            `}
                        >
                            <span
                                css={css`
                                    color: ${colors.gray};
                                    font-weight: bold;
                                `}
                            >
                                00
                            </span>
                            <span>{` - ${onlyMoveOut}`}</span>
                        </span>
                        <span
                            css={css`
                                padding-right: 2rem;
                            `}
                        >
                            <span
                                css={css`
                                    color: ${colors.gray};
                                    text-decoration: line-through;
                                `}
                            >
                                00
                            </span>
                            <span>{` - ${reservedDays}`}</span>
                        </span>
                    </div>
                </Fragment>
            )}
        </div>
    )
}

export default Calendar
