import React, { useEffect, useRef } from "react"
import { css } from "@emotion/core"
import styles from "../styles"
import { isBefore, isEqual, isSameDay, subDays } from "date-fns"
import { colors } from "../../../styles/constants"
import ReactTooltip from "react-tooltip"

export const WeekDay = ({
    day,
    date,
    onSelected,
    isInRange,
    unavailableDays,
    onMouseOver,
    isPossibleToSelect,
    isEndOfDateRange,
    moveInDays,
}) => {
    const currentDate = new Date(date)
    currentDate.setDate(day)

    const isUnavailable = unavailableDays && unavailableDays.includes(day)
    const isMoveInDay =
        !isUnavailable && checkIfItIsMoveInDay(date, day, moveInDays)
    const isPrevDayMoveInDay =
        isMoveInDay && checkIfPrevDayIsMoveInDay(date, day, moveInDays)
    const isPrevDayUnavailable =
        isMoveInDay && checkIfPrevDayUnavailable(day, unavailableDays)

    // if (day === 27) {
    //     console.log(
    //         "DAY: ",
    //         day,
    //         "isUnavailable: ",
    //         isUnavailable,
    //         "isMoveInDay: ",
    //         isMoveInDay,
    //         "isPrevDayMoveInDay: ",
    //         isPrevDayMoveInDay,
    //         "isPrevDayUnavailable: ",
    //         isPrevDayUnavailable
    //     )
    //     console.log("unavlidays")
    // }

    const dayRef = useRef(null)

    useEffect(() => {
        const localRef = dayRef
        localRef.current.addEventListener("mouseover", handleMouseOver)
        return () => {
            localRef.current.removeEventListener("mouseover", handleMouseOver)
        }
    }, [])

    const handleMouseOver = () => {
        onMouseOver(currentDate)
    }

    const isSelectable = isPossibleToSelect(currentDate)

    let style = css`
        ${isSelectable ? "cursor: pointer;" : ""}
    `
    let dayTextStyle = css``
    const isTotallyUnavailable =
        isUnavailable ||
        isBefore(currentDate, new Date()) ||
        isPrevDayUnavailable ||
        isPrevDayMoveInDay
    if (isTotallyUnavailable) {
        dayTextStyle = css`
            text-decoration: line-through;
            font-weight: normal;
            color: ${colors.gray};
        `
    } else if (isMoveInDay) {
        dayTextStyle = css`
            color: ${colors.gray};
        `
    } else if (isInRange(currentDate) && isSelectable) {
        const isEnd = isEndOfDateRange(currentDate)
        style = !isEnd && styles.includedDay
        dayTextStyle = css`
            color: ${isEnd ? "white" : ""};
        `
    }

    const handleClick = () => {
        if (!isUnavailable && !isBefore(currentDate, new Date())) {
            onSelected(currentDate)
        }
    }

    return (
        <li
            ref={dayRef}
            onClick={handleClick}
            css={css`
                ${style};
                ${css`
                    position: relative;
                `}
            `}
            data-tip={
                isMoveInDay
                    ? "Csak kiköltözés. Estére már foglalt a szállás."
                    : ""
            }
        >
            <div
                css={
                    isMoveInDay && !isTotallyUnavailable ? styles.halfDay : null
                }
            ></div>
            <div css={isEndOfDateRange(currentDate) && styles.selectedDay}>
                <div
                    className="date"
                    css={css`
                        ${styles.date};
                        ${dayTextStyle};
                        z-index: 10;
                        position: relative;
                    `}
                >
                    {day}
                </div>
            </div>
            <ReactTooltip />
        </li>
    )
}

export const OtherDay = () => <li className="other-day" css={styles.otherDay} />

function checkIfItIsMoveInDay(date, day, moveInDays) {
    const thisDaysDate = new Date(date)
    thisDaysDate.setDate(day)
    const isListedMoveInDay = moveInDays.some(d => isEqual(d, thisDaysDate))

    return isListedMoveInDay
}

function checkIfPrevDayIsMoveInDay(date, day, moveInDays) {
    let thisDaysDate = new Date(date)
    thisDaysDate.setDate(day)
    const prevDay = subDays(thisDaysDate, 1)

    const isPrevDayMoveInDay = moveInDays.some(d => isSameDay(d, prevDay))

    return isPrevDayMoveInDay
}

function checkIfPrevDayUnavailable(day, unavailableDays) {
    const prevDay = day - 1
    const isPrevDayUnavailable = unavailableDays.some(d => d === prevDay)

    return isPrevDayUnavailable
}
