import css from "@emotion/css"
import React from "react"
import { colors, desktopMediaQuery, sizes } from "../../styles/constants"
import Icons, { icon } from "../Icons"

export default function RadioButton({ onChange, text, isChecked }) {
    return (
        <div css={styles.radioContainer}>
            <Icons
                type={isChecked ? icon.RADIO_CHECKED : icon.RADIO_UNCHECKED}
                color={colors.primary}
                css={styles.radioButton}
                onClick={onChange}
            />
            <span>{text}</span>
        </div>
    )
}

const styles = {
    radioContainer: css`
        display: flex;
        align-items: center;
        padding: 0 ${sizes.smallScale.xl} ${sizes.smallScale.m} 0;
        ${desktopMediaQuery} {
            padding: 0 ${sizes.smallScale.xl} ${sizes.smallScale.xl} 0;
        }
    `,
    radioButton: css`
        padding-right: ${sizes.smallScale.xs};
        &:hover {
            cursor: pointer;
        }
    `,
}
