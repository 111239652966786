import React, { Fragment } from "react"
import { css } from "@emotion/core"
import { colors } from "../../styles/constants"

export const ICONS = {
    NEXT_ARROW: "next_arrow",
    PREV_ARROW: "prev_arrow",
    ADD: "add",
    REMOVE: "remove",
}

export function IconButton({
    iconName,
    color = colors.primary,
    onClick,
    width = "48px",
    height = "48px",
    disabled,
    className,
}) {
    const renderSvgBody = () => {
        switch (iconName) {
            case ICONS.NEXT_ARROW:
                return (
                    <Fragment>
                        <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                    </Fragment>
                )
            case ICONS.PREV_ARROW:
                return (
                    <Fragment>
                        <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                    </Fragment>
                )
            case ICONS.REMOVE:
                return (
                    <Fragment>
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                    </Fragment>
                )
            case ICONS.ADD:
                return (
                    <Fragment>
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                    </Fragment>
                )
            default:
                return null
        }
    }
    return (
        <button
            onClick={!disabled ? onClick : () => {}}
            className={className}
            css={css`
                background: white;
                border: 0px;
                outline: none;
                &:hover {
                    cursor: pointer;
                }
            `}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill={disabled ? colors.coldGray : color}
                width={width}
                height={height}
            >
                {renderSvgBody()}
            </svg>
        </button>
    )
}
