import React, { useState, useRef, useEffect } from "react"
import Header from "./Header"
import Weeks from "./Weeks"
import MonthStepper from "./MonthStepper"
import { css } from "@emotion/core"
import { isValidDateRange } from "../../../utils/date"
import { sizes, desktopMediaQuery } from "../../../styles/constants"
import { isEqual, isWithinInterval } from "date-fns"

function SelectorCalendar({
    locale,
    onRangeSelected,
    reservations,
    reservationIntervals,
    moveInDays,
}) {
    const [selectedMonth, setSelectedMonth] = useState(() => {
        const date = new Date()
        return {
            year: date.getFullYear(),
            month: date.getMonth(),
            day: 1,
        }
    })
    const [dateRange, setDateRange] = useState({ from: null, to: null })

    const [hoveredDate, setHoveredDate] = useState(null)

    const handleMouseOverDay = date => {
        if (prevDateRange.current.from && prevDateRange.current.to === null) {
            setHoveredDate(date)
        }
    }

    const prevDateRange = useRef(null)
    useEffect(() => {
        if (dateRange.from && dateRange.to) {
            onRangeSelected({ ...dateRange })
        } else {
            onRangeSelected(null)
        }
        prevDateRange.current = dateRange
    }, [dateRange, onRangeSelected])

    useEffect(() => {
        const validRange = isValidDateRange(
            dateRange.from,
            reservationIntervals,
            new Date(),
            dateRange.to,
            moveInDays
        )
        if(!validRange) {
            setDateRange({ from: null, to: null })
        }
    }, [reservationIntervals])

    const handleDateChange = (year, month) => {
        setSelectedMonth({
            ...selectedMonth,
            year,
            month,
        })
    }
    const handleOnSelect = date => {
        const movingDay = isMoveInDay(date)
        if (
            !dateRange.from ||
            (dateRange.from && dateRange.to) ||
            date.getTime() < dateRange.from.getTime() //clicking backwards
        ) {
            setDateRange({
                from: date,
                to: null,
            })
            setHoveredDate(null)
        } else if (
            movingDay &&
            !moveInDays.some(
                day =>
                    !isEqual(day, date) &&
                    isWithinInterval(
                        day,
                        {
                            start: dateRange.from,
                            end: date,
                        },
                        { inclusive: false }
                    )
            )
        ) {
            setDateRange({
                ...dateRange,
                to: date,
            })
        } else if (!isValidToDate(date) && !movingDay) {
            setDateRange({
                from: date,
                to: null,
            })
            setHoveredDate(null)
        } else if (dateRange.from.getTime() < date.getTime() && !movingDay) {
            setDateRange({
                ...dateRange,
                to: date,
            })
        }
    }

    const isMoveInDay = date => {
        const thisDaysDate = new Date(date)
        return moveInDays.some(day => isEqual(day, thisDaysDate))
    }

    const isValidToDate = date => {
        return isValidDateRange(
            dateRange.from,
            reservationIntervals,
            new Date(),
            date,
            moveInDays
        )
    }

    return (
        <div>
            <MonthStepper
                onDateChange={handleDateChange}
                locale={locale}
                currentDate={selectedMonth}
            />
            <div css={styles.calendarsContainer}>
                <div css={styles.calendarCurrent}>
                    <Header locale={locale} />
                    <Weeks
                        selectedMonthDate={
                            new Date(
                                selectedMonth.year,
                                selectedMonth.month,
                                selectedMonth.day
                            )
                        }
                        unavailableRanges={reservationIntervals}
                        dateRange={dateRange}
                        onSelect={handleOnSelect}
                        isValidRange={isValidToDate}
                        hoveredDate={hoveredDate}
                        handleMouseOverDay={handleMouseOverDay}
                        moveInDays={moveInDays}
                    />
                </div>
                <div css={styles.calendarNextMonth}>
                    <Header locale={locale} />
                    <Weeks
                        selectedMonthDate={
                            new Date(
                                selectedMonth.year,
                                selectedMonth.month + 1,
                                selectedMonth.day
                            )
                        }
                        unavailableRanges={reservationIntervals}
                        dateRange={dateRange}
                        onSelect={handleOnSelect}
                        isValidRange={isValidToDate}
                        hoveredDate={hoveredDate}
                        handleMouseOverDay={handleMouseOverDay}
                        moveInDays={moveInDays}
                    />
                </div>
            </div>
        </div>
    )
}

export default SelectorCalendar

const styles = {
    calendarsContainer: css`
        width: 100%;
        display: grid;
        grid-template-rows: 50% 50%;
        grid-template-areas: "calendarCurrent calendarCurrent";
        ${desktopMediaQuery} {
            grid-template-areas: "calendarCurrent calendarNextMonth";
            grid-gap: ${sizes.smallScale.m};
        }
    `,
    calendarCurrent: css`
        grid-area: calendarCurrent;
    `,
    calendarNextMonth: css`
        grid-area: calendarNextMonth;
        display: none;
        ${desktopMediaQuery} {
            display: block;
        }
    `,
}
