import React, { Fragment } from "react"
import { pStyle } from "../styles/constants"

function PWithBr({ children, className }) {
  return (
    <div className={className}>
      {children.split("\n").map((t, i) => (
        <Fragment key={i}>
          <p css={pStyle.justified}>{t}</p>
          <br />
        </Fragment>
      ))}
    </div>
  )
}
export default PWithBr
