import React, { useMemo } from "react"
import { getISODay, getDaysInMonth, isEqual } from "date-fns"
import styles from "../styles"
import { FirstRow, MiddleRows, LastRow } from "./WeekRows"
import { getReservedDays } from "../../../utils/date"

class DateProps {
    constructor(currentMonthDate) {
        this.currentMonthDate = currentMonthDate
        this.currentDay = getISODay(currentMonthDate)
        this.currentMonthDays = getDaysInMonth(currentMonthDate)
        // NOTE itt -1 mert hetforol indulunk nem vasranprol, es !== 1, mert hetfo egy es csak hetfon nincs elotte ures nap
        this.beginningEmptyDayCount =
            this.currentDay !== 1 ? this.currentDay - 1 : 0
        this.numberOfLastDays =
            (this.beginningEmptyDayCount + this.currentMonthDays) % 7
        this.endEmptyDayCount = 7 - this.numberOfLastDays
        this.sumOfDays =
            this.beginningEmptyDayCount +
            this.endEmptyDayCount +
            this.currentMonthDays
    }
}

function Weeks({
    unavailableRanges,
    selectedMonthDate,
    dateRange,
    onSelect,
    isValidRange,
    handleMouseOverDay,
    hoveredDate,
    moveInDays,
}) {
    const dateProps = new DateProps(selectedMonthDate)

    const isInDateRange = date => {
        if (dateRange.from && dateRange.to) {
            return (
                date.getTime() >= dateRange.from.getTime() &&
                date.getTime() <= dateRange.to.getTime()
            )
        } else if (dateRange.from) {
            const isSelectedFromDay =
                date.getTime() === dateRange.from.getTime()
            const isHoveredDay =
                hoveredDate &&
                date.getTime() >= dateRange.from.getTime() &&
                date.getTime() <= hoveredDate.getTime()
            return isSelectedFromDay || isHoveredDay
        }
        return false
    }

    const reservedDays = useMemo(() => {
        return getReservedDays(
            unavailableRanges,
            dateProps.currentMonthDate,
            dateProps.currentMonthDays
        )
    }, [
        dateProps.currentMonthDate,
        dateProps.currentMonthDays,
        unavailableRanges,
    ])

    const isEndOfDateRange = date => {
        return isEqual(date, dateRange.from) || isEqual(date, dateRange.to)
    }

    const commonRowProps = {
        isInDateRange: isInDateRange,
        unavailableDays: reservedDays,
        onSelected: onSelect,
        onMouseOver: handleMouseOverDay,
        isPossibleToSelect: isValidRange,
        isEndOfDateRange: isEndOfDateRange,
        moveInDays: moveInDays,
    }

    return (
        <div css={styles.days}>
            <FirstRow dateProps={dateProps} {...commonRowProps} />
            <MiddleRows dateProps={dateProps} {...commonRowProps} />
            {/* 7 would mean there is a full empty row, and we dont want to display that */}
            {dateProps.endEmptyDayCount !== 7 && (
                <LastRow dateProps={dateProps} {...commonRowProps} />
            )}
        </div>
    )
}

export default Weeks
