import { useQuery } from "react-query"
import { getRanges } from "."

export function useRanges() {
    
    const queryResult = useQuery("ranges", () => getRanges(), {staleTime: 1800000})
    
    return {
        ...queryResult,
        data: queryResult?.data?.data ?? []
    }  
}