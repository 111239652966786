import React from "react"
import { WeekDay, OtherDay } from "./Days"
import { css } from "@emotion/core"
import { format } from "date-fns"

export function FirstRow(props) {
    const inMonthDays = []
    const prevMonthDays = []
    for (let i = 0; i < props.dateProps.beginningEmptyDayCount; i++) {
        prevMonthDays.push(i)
    }
    for (let j = 1; j <= 7 - props.dateProps.beginningEmptyDayCount; j++) {
        inMonthDays.push(j)
    }
    return (
        <Rows
            {...props}
            inMonthDays={inMonthDays}
            prevMonthDays={prevMonthDays}
        />
    )
}

export function MiddleRows(props) {
    const numberOfWeeks = props.dateProps.sumOfDays / 7
    const firstMiddleDay = 7 - props.dateProps.beginningEmptyDayCount + 1
    const days = []
    for (let i = 0; i < numberOfWeeks - 2; i++) {
        const firstDayOfWeek = firstMiddleDay + i * 7
        for (let j = firstDayOfWeek; j < firstDayOfWeek + 7; j++) {
            days.push(j)
        }
    }
    return <Rows {...props} inMonthDays={days} />
}

export function LastRow(props) {
    const firstDayLastRowDay =
        props.dateProps.currentMonthDays - props.dateProps.numberOfLastDays + 1
    const inMonthDays = []
    const nextMonthDays = []
    for (
        let i = firstDayLastRowDay;
        i <= props.dateProps.currentMonthDays;
        i++
    ) {
        inMonthDays.push(i)
    }
    for (let j = 0; j < props.dateProps.endEmptyDayCount; j++) {
        nextMonthDays.push(j)
    }
    return (
        <Rows
            {...props}
            inMonthDays={inMonthDays}
            nextMonthDays={nextMonthDays}
        />
    )
}

function Rows(props) {
    const inMonthDays = props.inMonthDays.map(day => (
        <WeekDay
            onSelected={props.onSelected}
            day={day}
            date={props.dateProps.currentMonthDate}
            isInRange={props.isInDateRange}
            unavailableDays={props.unavailableDays}
            key={`${format(
                props.dateProps.currentMonthDate,
                "Y LLLL d"
            )}${day}`}
            onMouseOver={props.onMouseOver}
            isPossibleToSelect={props.isPossibleToSelect}
            isEndOfDateRange={props.isEndOfDateRange}
            moveInDays={props.moveInDays}
        />
    ))

    const nextMonthDays =
        props.nextMonthDays &&
        props.nextMonthDays.map(day => (
            <OtherDay
                key={`${format(
                    props.dateProps.currentMonthDate,
                    "Y LLLL d"
                )}${day}-other-next`}
            />
        ))

    const prevMonthDays =
        props.prevMonthDays &&
        props.prevMonthDays.map(day => (
            <OtherDay
                key={`${format(
                    props.dateProps.currentMonthDate,
                    "Y LLLL d"
                )}${day}-other-prev`}
            />
        ))

    return (
        <ul
            css={css`
                display: flex;
                flex-wrap: wrap;
            `}
        >
            {prevMonthDays}
            {inMonthDays}
            {nextMonthDays}
        </ul>
    )
}
