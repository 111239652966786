export function trimSpace(str) {
    if (!str) return str
    return str.replace(/^\s+/g, "")
}

export function thousandSpacing(number) {
    const stringed = number.toString()
    if (stringed.length <= 3) return stringed
    else {
        let paddedNumber = ""
        for (let i = 0; i < stringed.length / 3; i++) {
            let offset = i * 3
            paddedNumber =
                stringed.substring(
                    stringed.length - 3 - offset,
                    stringed.length - offset
                ) +
                " " +
                paddedNumber
        }
        return paddedNumber
    }
}
