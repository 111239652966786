import React, { useState, memo, useRef, Fragment } from "react"
import { css } from "@emotion/core"
import BookingInformation from "./BookingInformations"
import PersonalInformation from "./PersonalInformation"
import NotesSection from "./NotesSection"
import {
  desktopMediaQuery,
  colors,
  centerContent,
  fontSizes,
} from "../../styles/constants"
import { saveReservation, sendBookingForm } from "../../services"
import { formatReadableRange, listEuCountries } from "../../utils/date"
import { PrimaryButton } from "../Button"
import Spinner from "../Spinner"
import ErrorMsg from "../ErrorMsg"
import { useEffect } from "react"
import { PriceInfoBar } from "."
import { useLocalized } from "../LocalizedLink"
import { useContext } from "react"
import { PriceCtx, TextContentCtx } from "../../templates/booking-page"
import PaymentInformation, { method } from "./PaymentInformation"
import { format } from "date-fns"
import { LanguageCtx } from "../../context"
import AcceptTerms from "./AcceptTerms"
import { useCallback } from "react"

//TODO
const SWITCH_OFF_CALENDAR = false
const SZEP_CARD_MULTIPLIER = 1.035

function BookingWindow({ bookingDateRange, windowContentCss }) {
  const { booking, send } = useContext(TextContentCtx)
  const { lang } = useContext(LanguageCtx)
  const priceCtx = useContext(PriceCtx)

  const formRef = useRef()

  const [navigate] = useLocalized()

  const [submitState, setSubmitState] = useState({
    isLoading: false,
    isError: false,
  })

  const [values, setValues] = useState({
    numberOfGuests: 2,
    country: listEuCountries[lang].HU,
    paymentMethod: method.TRANSFER,
  })

  const [incorrectValues, setIncorrectValues] = useState([])

  const [termsState, setTermsState] = useState({
    isAccepted: false,
    isError: false,
  })

  const requiredDataKeys = [
    { key: "firstname" },
    { key: "familyname" },
    { key: "dateOfBirth" },
    { key: "postcode" },
    { key: "city" },
    { key: "street" },
    {
      key: "phone",
      validator: value => {
        const regexp = RegExp(
          /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm
        )
        return regexp.test(value)
      },
    },
    {
      key: "email",
      validator: value => {
        const regexp = RegExp(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)
        return regexp.test(value)
      },
    },
  ]

  useEffect(() => {
    setValues(v => ({
      ...v,
      bookedRange: bookingDateRange && formatReadableRange(bookingDateRange),
    }))
  }, [bookingDateRange])

  useEffect(() => {
    if (values.paymentMethod === method.SZEP_CARD) {
      const modifiedPrice =
        Number(values.finalPrice.replace(" ", "")) * SZEP_CARD_MULTIPLIER
      const roundedPrice = Math.ceil(modifiedPrice / 10) * 10
      priceCtx.setPrice({
        ...priceCtx.price,
        finalPrice: roundedPrice,
      })
    } else {
      priceCtx.setPrice({
        ...priceCtx.price,
        finalPrice: values.finalPrice,
      })
    }
  }, [values.finalPrice, values.paymentMethod])

  const handleSubmit = async event => {
    event.preventDefault()
    const problematicKeys = requiredDataKeys.filter(element => {
      return (
        !values[element.key] ||
        (element.validator !== undefined &&
          !element.validator(values[element.key]))
      )
    })
    if (problematicKeys.length === 0 && termsState.isAccepted) {
      setSubmitState({
        isLoading: true,
        isError: false,
      })
      try {
        const bookingToSave = {
          ...values,
          ...bookingDateRange,
          finalPrice: priceCtx.price.finalPrice ?? values.finalPrice,
          endDateISO: format(bookingDateRange.to, "y-MM-dd"), // this is for sorting for Fauna
        }
        await sendBookingForm(
          {
            ...values,
            finalPrice: priceCtx.price.finalPrice ?? values.finalPrice,
          },
          formRef.current
        )
        await saveReservation(bookingToSave)

        navigate("/foglalas/koszonjuk", {
          state: bookingToSave,
        })
      } catch {
        setSubmitState({
          isLoading: false,
          isError: true,
        })
      } finally {
        setIncorrectValues([])
      }
    } else {
      setIncorrectValues(problematicKeys)
      setTermsState({
        ...termsState,
        isError: !termsState.isAccepted,
      })
    }
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const handleGuestNumberChange = cnt => {
    setValues({
      ...values,
      numberOfGuests: cnt,
    })
  }

  const handleCountryChange = selectedCountry => {
    setValues({
      ...values,
      country: selectedCountry,
    })
  }

  const handlePaymentMethodChange = selectedMethod => {
    setValues({
      ...values,
      paymentMethod: selectedMethod,
    })
  }

  const handleTermsChange = () => {
    setTermsState(s => ({
      ...s,
      isAccepted: !s.isAccepted,
    }))
  }

  const handleDiscountSelected = (discount, discountCode) => {
    let discountValue = discount < 1 ? `${discount * 100}%` : `-${discount} Ft`
    setValues({
      ...values,
      discount: discountValue,
      discountCode,
    })
  }

  const handleFinalPriceChange = useCallback(price => {
    setValues(s => ({
      ...s,
      finalPrice: price,
    }))
  }, [])

  return (
    <Fragment>
      <div
        css={css`
          ${styles.form.content};
          ${windowContentCss};
        `}
      >
        <div css={styles.topLine} />
        <form
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          ref={formRef}
          css={styles.form.informationContainer}
          onSubmit={event => event.preventDefault()}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          {/* This hidden div is to lure the bots */}
          <div hidden>
            <label>
              Don't fill this form out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </div>
          <BookingInformation
            bookingRange={bookingDateRange}
            numberOfGuests={values.numberOfGuests}
            onGuestNumberChange={handleGuestNumberChange}
            onDiscountSelected={handleDiscountSelected}
            finalPrice={values.finalPrice}
            onFinalPriceChange={handleFinalPriceChange}
          />
          <div css={styles.divider} />
          <PersonalInformation
            selectedCountry={values.country}
            onChange={handleChange}
            onCountryChange={handleCountryChange}
            requiredDataKeys={requiredDataKeys}
            missingValues={incorrectValues}
          />
          <div css={styles.divider} />
          <PaymentInformation
            onChange={handlePaymentMethodChange}
            paymentMethod={values.paymentMethod}
          />
          <NotesSection onChange={handleChange} />
          <AcceptTerms state={termsState} onChange={handleTermsChange} />
          <div className="field" css={styles.sendButton}>
            {SWITCH_OFF_CALENDAR && (
              <p
                css={css`
                  padding-right: 2rem;
                  font-weight: bold;
                  font-family: "Besley", serif;
                  color: ${colors.primary};
                  font-size: ${fontSizes.large};
                `}
              >
                Foglalas hamarosan...
              </p>
            )}
            {(submitState.isLoading && <Spinner />) || (
              <PrimaryButton
                className={`is-link ${submitState.isLoading && "is-loading"}`}
                disabled={
                  submitState.isLoading ||
                  !values.bookedRange ||
                  SWITCH_OFF_CALENDAR
                }
                onClick={handleSubmit}
              >
                {send}
              </PrimaryButton>
            )}
          </div>
        </form>
        <PriceInfoBar css={styles.priceInfoBar} />
      </div>
      {submitState.isError && (
        <div
          css={css`
            ${centerContent};
            padding-top: 3rem;
          `}
        >
          <ErrorMsg
            header={booking.bookingErrorTitle}
            body={booking.bookingErrorBody}
          />
        </div>
      )}
    </Fragment>
  )
}

export default memo(BookingWindow)

const styles = {
  form: {
    content: css`
      display: flex;
      position: relative;
      border-radius: 4px;
      width: 100%;
      background: white;
      touch-action: manipulation;
      & label {
        color: ${colors.primary800};
      }
    `,
    informationContainer: css`
      width: 100%;
      padding-bottom: 1.08rem;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      ${desktopMediaQuery} {
        width: 85%;
      }
    `,
  },
  topLine: css`
    width: 100%;
    position: absolute;
    top: 0;
    background: ${colors.primary};
    height: 0.5rem;
    ${desktopMediaQuery} {
      border-top-left-radius: 4px;
      width: 80%;
    }
  `,
  nameMailContainer: css`
    ${desktopMediaQuery} {
      display: flex;
      justify-content: space-between;
      width: 36rem;
    }
  `,
  priceInfoBar: css`
    display: none;
    ${desktopMediaQuery} {
      display: block;
    }
  `,
  divider: css`
    height: 2px;
    background: ${colors.gray};
  `,
  sendButton: css`
    ${centerContent};
    padding: 0 1rem;
    button {
      width: 100%;
    }
    ${desktopMediaQuery} {
      padding: 0 2rem 2rem 0;
      float: right;
    }
  `,
}
