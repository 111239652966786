import React from "react"
import { css } from "@emotion/core"
import { IconButton, ICONS } from "../Button/IconButton"
import { graphql, StaticQuery } from "gatsby"
import { useContext } from "react"
import { TextContentCtx } from "../../templates/booking-page"

function GuestNumberSelector({ numberOfGuests, onChange, ...props }) {
    const textContent = useContext(TextContentCtx)

    return (
        <StaticQuery
            query={graphql`
                query GuestNumber {
                    allFile(filter: { sourceInstanceName: { eq: "data" } }) {
                        edges {
                            node {
                                childMarkdownRemark {
                                    frontmatter {
                                        maxGuest
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={data => {
                const {
                    maxGuest,
                } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
                const handleClick = direction => () => {
                    onChange(
                        direction === "+"
                            ? numberOfGuests + 1
                            : numberOfGuests - 1
                    )
                }
                return (
                    <div className="field" {...props}>
                        <label
                            className="label"
                            css={css`
                                user-select: none;
                            `}
                        >
                            {textContent.booking.numberOfGuests}
                        </label>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                width: 10rem;
                            `}
                        >
                            <IconButton
                                iconName={ICONS.REMOVE}
                                width="32px"
                                height="32px"
                                disabled={numberOfGuests === 1}
                                onClick={handleClick("-")}
                                css={css`
                                    padding: 4px 0 0 0;

                                    & svg {
                                        margin-right: 0.5rem;
                                    }
                                `}
                            />
                            <div
                                css={css`
                                    min-width: 1rem;
                                    text-align: center;
                                    user-select: none;
                                `}
                            >
                                {numberOfGuests}
                            </div>
                            <IconButton
                                iconName={ICONS.ADD}
                                width="32px"
                                height="32px"
                                disabled={numberOfGuests === maxGuest}
                                onClick={handleClick("+")}
                                css={css`
                                    padding: 4px 0 0 0;

                                    & svg {
                                        margin-left: 0.5rem;
                                    }
                                `}
                            />
                        </div>
                    </div>
                )
            }}
        />
    )
}

export default GuestNumberSelector
