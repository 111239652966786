import React, { Fragment, useEffect } from "react"
import GuestNumberSelector from "./GuestNumberSelector"
import { differenceInCalendarDays } from "date-fns"
import {
    fontSizes,
    colors,
    desktopMediaQuery,
    sizes,
} from "../../styles/constants"
import { css } from "@emotion/core"
import { formatReadableRange } from "../../utils/date"
import SectionTitle from "./SectionTitle"
import { thousandSpacing } from "../../utils/string"
import { useContext } from "react"
import { TextContentCtx } from "../../templates/booking-page"
import { useStaticQuery, graphql } from "gatsby"
import { calculatePrice } from "../../utils/utils"
import PromoCode from "./PromoCode"
import { useState } from "react"

function BookingInformation({
    bookingRange,
    numberOfGuests,
    onGuestNumberChange,
    onDiscountSelected,
    finalPrice,
    onFinalPriceChange,
}) {
    const textContent = useContext(TextContentCtx)

    const [discount, setDiscount] = useState(0)

    const data = useStaticQuery(graphql`
        query BookingInformation {
            allFile(filter: { sourceInstanceName: { eq: "data" } }) {
                edges {
                    node {
                        childMarkdownRemark {
                            frontmatter {
                                basePrice
                                perPersonPrice
                                couplePrice
                                tax
                            }
                        }
                    }
                }
            }
        }
    `)

    const numberOfNights =
        bookingRange && bookingRange.to && bookingRange.from
            ? differenceInCalendarDays(bookingRange.to, bookingRange.from)
            : 0
    const {
        basePrice,
        perPersonPrice,
        couplePrice,
        tax,
    } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter

    useEffect(() => {
        onFinalPriceChange(
            thousandSpacing(
                calculatePrice(
                    numberOfNights,
                    basePrice,
                    numberOfGuests,
                    perPersonPrice,
                    undefined,
                    discount,
                    couplePrice,
                    tax
                )
            )
        )
    }, [
        numberOfNights,
        basePrice,
        numberOfGuests,
        perPersonPrice,
        discount,
        onFinalPriceChange,
        couplePrice,
        tax,
    ])

    const handleDiscountChange = (discountAmount, selectedDiscountCode) => {
        setDiscount(discountAmount)
        onDiscountSelected(discountAmount, selectedDiscountCode)
    }

    return (
        <div css={styles.container}>
            <SectionTitle css={styles.title} order={1}>
                {textContent.booking.bookingInformation}
            </SectionTitle>

            {/* TODO erre itt szukseg van ?  */}
            <input name="bookedRange" id="bookedRange" type="hidden" />
            <input name="numberOfGuests" id="numberOfGuest" type="hidden" />
            <input name="discount" id="discount" type="hidden" />
            <input name="finalPrice" id="finalPrice" type="hidden" />
            <input name="discountCode" id="discountCode" type="hidden" />
            <div css={[styles.period, styles.label]}>
                <label className="label">
                    {textContent.booking.bookingPeriod}
                </label>
                <div css={styles.nights}>
                    {(bookingRange && (
                        <Fragment>
                            <div>{formatReadableRange(bookingRange)}</div>
                            <div css={styles.numberOfNights}>
                                {numberOfNights} {textContent.booking.night}
                            </div>
                        </Fragment>
                    )) || (
                        <span css={styles.periodHelper}>
                            {textContent.booking.selectedPeriod}
                        </span>
                    )}
                </div>
            </div>
            <GuestNumberSelector
                css={[styles.guests, styles.label]}
                numberOfGuests={numberOfGuests}
                onChange={onGuestNumberChange}
            />
            <PromoCode
                css={styles.promoCode}
                onDiscountChange={handleDiscountChange}
            />
            <div css={[styles.priceTag, styles.label]}>
                <label className="label">
                    {textContent.booking.finalPrice}
                </label>
                {discount !== 0 && (
                    <span css={styles.infoText}>
                        {discount < 1
                            ? `(${discount * 100}%)`
                            : `(-${discount} Ft)`}
                    </span>
                )}
                {finalPrice} Ft
                <span css={styles.priceSubInfo}>
                    {textContent.info.priceSubInfo}
                    {`(${tax} Ft /éj/fő)`}
                </span>
            </div>
        </div>
    )
}

export default BookingInformation

const styles = {
    container: css`
        padding: 2rem 1rem;
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas:
            "title"
            "period"
            "guests"
            "sum"
            "promoCode";
        ${desktopMediaQuery} {
            padding: 2rem;
            grid-template-columns: 60% 40%;
            grid-template-areas:
                "title title"
                "period guests "
                "sum promoCode";
        }
    `,
    label: css`
        & label {
            margin-bottom: 0.5rem !important;
        }
    `,
    title: css`
        grid-area: title;
    `,

    priceTag: css`
        grid-area: sum;
        font-size: ${fontSizes.xxLarge};
        color: ${colors.primary};
        padding-bottom: 2rem;
    `,
    nights: css`
        min-height: 3rem;
    `,
    numberOfNights: css`
        color: ${colors.darkGray};
        width: 90%;
    `,
    period: css`
        grid-area: period;
        padding-bottom: 2rem;
    `,
    periodHelper: css`
        color: ${colors.primary};
        font-style: italic;
        width: 80%;
    `,
    guests: css`
        grid-area: guests;
        padding-bottom: 2rem;
    `,
    promoCode: css`
        grid-area: promoCode;
        padding-bottom: 2rem;
    `,
    priceSubInfo: css`
        color: ${colors.darkGray};
        display: block;
        font-size: ${fontSizes.default};
        font-style: italic;
        width: 80%;
    `,
    infoText: css`
        width: 50%;
        color: ${colors.green};
        font-size: ${fontSizes.default};
        padding-right: ${sizes.smallScale.xs};
        font-style: italic;
    `,
}
