import React, { Fragment } from "react"
import { css } from "@emotion/core"
import { sizes, colors, desktopMediaQuery } from "../../styles/constants"
import SectionTitle from "./SectionTitle"
import { useContext } from "react"
import { PriceCtx, TextContentCtx } from "../../templates/booking-page"
import { useStaticQuery, graphql } from "gatsby"
import RadioButton from "./RadioButton"
import { thousandSpacing } from "../../utils/string"

export const method = {
  CREDIT_CARD: "creditcard",
  CASH: "cash",
  TRANSFER: "transfer",
  SZEP_CARD: "szepCard",
}

function PaymentInformation({ paymentMethod, onChange }) {
  const { paying } = useContext(TextContentCtx)
  const priceCtx = useContext(PriceCtx)

  const data = useStaticQuery(graphql`
    query PaymentInfor {
      allFile(filter: { sourceInstanceName: { eq: "data" } }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                bankAccount
                accountHolder
                payingAddress
              }
            }
          }
        }
      }
    }
  `)

  const handlePaymentMethodChange = method => () => {
    onChange(method)
  }

  const {
    bankAccount,
    accountHolder,
    payingAddress,
  } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const renderPaymentMethodDescription = () => {
    if (paymentMethod === method.TRANSFER) {
      return (
        <Fragment>
          <span>{paying.transferNote}</span>
          <div css={styles.infoHighlight}>
            {accountHolder}
            <br />
            {bankAccount}
          </div>
        </Fragment>
      )
    } else if (paymentMethod === method.CASH) {
      return (
        <Fragment>
          <span>{paying.personalCashNote}</span>
          <div css={styles.infoHighlight}>{payingAddress}</div>
        </Fragment>
      )
    } else if (paymentMethod === method.SZEP_CARD) {
      return (
        <Fragment>
          <div
            css={[
              styles.infoHighlight,
              css`
                padding: 0 0 ${sizes.smallScale.l} 0;
              `,
            ]}
          >
            {
              "Figyelem! OTP Szép Kártyás fizetés estén 3,5% felárat számolunk fel. Így a végösszeg: "
            }
            {`${
              priceCtx.price.finalPrice
                ? thousandSpacing(priceCtx.price.finalPrice)
                : 0
            } Ft`}
          </div>
          <span>{paying.szepCardNote}</span>
          <br />
          <br />
          <a href="https://magan.szepkartya.otpportalok.hu/felhasznalas/">
            Információ az OTP Szép Kártya előlegfizetésről
          </a>
        </Fragment>
      )
    } else {
      return null
    }
  }

  return (
    <div css={styles.container}>
      <input name="paymentMethod" id="paymentMethod" type="hidden" />
      <SectionTitle order={3}>{paying.payingMethod}</SectionTitle>
      <div css={styles.optionsContainer}>
        <RadioButton
          onChange={handlePaymentMethodChange(method.TRANSFER)}
          text={paying.transfer}
          isChecked={paymentMethod === method.TRANSFER}
        />
        {/* <RadioButton
          onChange={handlePaymentMethodChange(method.CASH)}
          text={paying.personalCash}
          isChecked={paymentMethod === method.CASH}
        /> */}
        <RadioButton
          onChange={handlePaymentMethodChange(method.SZEP_CARD)}
          text={paying.szepCard}
          isChecked={paymentMethod === method.SZEP_CARD}
        />
      </div>
      <div css={styles.infoSection}>
        <div css={styles.infoContainer}>{renderPaymentMethodDescription()}</div>
      </div>
    </div>
  )
}

export default PaymentInformation

const styles = {
  container: css`
    padding: ${sizes.smallScale.xl} ${sizes.smallScale.m};
    ${desktopMediaQuery} {
      padding: ${sizes.smallScale.xl};
    }
  `,
  optionsContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  infoContainer: css`
    border: 2px solid ${colors.primary400};
    padding: ${sizes.smallScale.l};
    border-radius: 4px;
    background: ${colors.primary200};
    color: ${colors.primary600};
    ${desktopMediaQuery} {
      padding: ${sizes.smallScale.xl};
    }
  `,
  infoHighlight: css`
    font-weight: bold;
    padding-top: ${sizes.smallScale.l};
    color: ${colors.primary700};
  `,
  infoSection: css`
    min-height: ${sizes.largeScale.s};
  `,
}
