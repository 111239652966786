import React, { useState, useCallback, createContext } from "react"
import { graphql } from "gatsby"
import Calendar from "../components/Calendar"
import { css } from "@emotion/core"
import {
  desktopMediaQuery,
  pageTitle,
  backgroundPatterns,
} from "../styles/constants"
import { PriceInfoBar, BookingWindow } from "../components/Booking"
import { Section } from "../components/Styled"
import { QueryClient, QueryClientProvider } from "react-query"

export const TextContentCtx = createContext({})
export const PriceCtx = createContext()

function BookingPage({ data }) {
  const queryClient = new QueryClient()

  const {
    title,
    freeDays,
    onlyMoveOut,
    reservedDays,
    dateOfBirth,
  } = data.markdownRemark.frontmatter
  const textContent = {
    info: {
      pricesTitle: data.markdownRemark.frontmatter.pricesTitle,
      priceMainInfo: data.markdownRemark.frontmatter.priceMainInfo,
      basePriceUnit: data.markdownRemark.frontmatter.basePriceUnit,
      perPersonUnit: data.markdownRemark.frontmatter.perPersonUnit,
      priceSubInfo: data.markdownRemark.frontmatter.priceSubInfo,
      possibilities: data.markdownRemark.frontmatter.possibilities,
      question: data.markdownRemark.frontmatter.question,
    },
    booking: {
      bookingInformation: data.markdownRemark.frontmatter.bookingInformation,
      bookingPeriod: data.markdownRemark.frontmatter.bookingPeriod,
      selectedPeriod: data.markdownRemark.frontmatter.selectedPeriod,
      night: data.markdownRemark.frontmatter.night,
      numberOfGuests: data.markdownRemark.frontmatter.numberOfGuests,
      finalPrice: data.markdownRemark.frontmatter.finalPrice,
      bookingErrorTitle: data.markdownRemark.frontmatter.bookingErrorTitle,
      bookingErrorBody: data.markdownRemark.frontmatter.bookingErrorBody,
      promoUse: data.markdownRemark.frontmatter.promoUse,
      promoTitle: data.markdownRemark.frontmatter.promoTitle,
    },
    contact: {
      yourInformation: data.markdownRemark.frontmatter.yourInformation,
      surname: data.markdownRemark.frontmatter.surname,
      familyName: data.markdownRemark.frontmatter.familyName,
      dateOfBirth,
      country: data.markdownRemark.frontmatter.country,
      postcode: data.markdownRemark.frontmatter.postcode,
      city: data.markdownRemark.frontmatter.city,
      address: data.markdownRemark.frontmatter.address,
      address2: data.markdownRemark.frontmatter.address2,
      phoneNumber: data.markdownRemark.frontmatter.phoneNumber,
      email: data.markdownRemark.frontmatter.email,
    },
    paying: {
      payingMethod: data.markdownRemark.frontmatter.payingMethod,
      creditCard: data.markdownRemark.frontmatter.creditCard,
      personalCash: data.markdownRemark.frontmatter.personalCash,
      szepCard: data.markdownRemark.frontmatter.szepCard,
      personalCashNote: data.markdownRemark.frontmatter.personalCashNote,
      transfer: data.markdownRemark.frontmatter.transfer,
      transferNote: data.markdownRemark.frontmatter.transferNote,
      szepCardNote: data.markdownRemark.frontmatter.szepCardNote,
    },
    notes: data.markdownRemark.frontmatter.notes,
    send: data.markdownRemark.frontmatter.send,
    calendarLoadingError: data.markdownRemark.frontmatter.calendarLoadingError,
    acceptTerms: data.markdownRemark.frontmatter.acceptTerms,
    termsAndConditions: data.markdownRemark.frontmatter.termsAndConditions,
    termsAndCondition: data.markdownRemark.frontmatter.termsAndCondition,
    errorTitle: data.markdownRemark.frontmatter.errorTitle,
  }

  const [price, setPrice] = useState({ finalPrice: 0 })

  const [bookingDateRange, setBookingDateRange] = useState({
    from: null,
    to: null,
  })

  const handleRangeSelected = useCallback(range => {
    setBookingDateRange(range)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <TextContentCtx.Provider value={textContent}>
        <PriceCtx.Provider value={{ price, setPrice }}>
          <div css={backgroundPatterns.grayish}>
            <h1
              className="title is-1 has-text-centered"
              css={styles.title}
              data-testid="booking_title"
            >
              {title}
            </h1>
            <Section css={styles.section}>
              <div className="container" css={styles.container}>
                <PriceInfoBar css={styles.priceInfoBar} />
                <Calendar
                  onRangeSelected={handleRangeSelected}
                  containerCss={styles.calendar}
                  dayTypeText={{
                    freeDays,
                    onlyMoveOut,
                    reservedDays,
                  }}
                />
                <BookingWindow
                  bookingDateRange={bookingDateRange}
                  windowContentCss={styles.bookingWindow}
                />
              </div>
            </Section>
          </div>
        </PriceCtx.Provider>
      </TextContentCtx.Provider>
    </QueryClientProvider>
  )
}

export default BookingPage

export const bookingQuery = graphql`
  query BookingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        freeDays
        onlyMoveOut
        reservedDays
        dateOfBirth
        errorTitle
        calendarLoadingError
        pricesTitle
        priceMainInfo
        basePriceUnit
        perPersonUnit
        priceSubInfo
        possibilities
        question
        promoUse
        promoTitle
        bookingInformation
        bookingPeriod
        night
        selectedPeriod
        szepCardNote
        numberOfGuests
        finalPrice
        bookingErrorTitle
        bookingErrorBody
        yourInformation
        surname
        familyName
        country
        postcode
        city
        address
        address2
        phoneNumber
        email
        payingMethod
        szepCard
        creditCard
        personalCash
        transfer
        transferNote
        personalCashNote
        notes
        acceptTerms
        termsAndConditions
        send
        termsAndCondition
      }
    }
    background: file(relativePath: { eq: "bg3.JPG" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const styles = {
  section: css`
    & label {
      letter-spacing: 1px;
    }
  `,
  title: css`
    ${pageTitle};
  `,
  background: css`
    @media (max-width: 50rem) {
      position: static !important;
      background-origin: border-box;
      background-repeat: no-repeat;
      background-position: top !important;
      background-size: 100%;
    }
  `,
  container: css`
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
      "price"
      "calendar"
      "booking";
    ${desktopMediaQuery} {
      display: block;
    }
  `,
  priceInfoBar: css`
    grid-area: price;
    width: 100%;
    border-radius: 0px;
    ${desktopMediaQuery} {
      display: none;
    }
  `,
  calendar: css`
    grid-area: calendar;
    padding-top: 3rem;
  `,
  bookingWindow: css`
    grid-area: booking;
  `,
}
