import React, { useState } from "react"
import Icons, { icon } from "../Icons"
import { useContext } from "react"
import { TextContentCtx } from "../../templates/booking-page"
import { css } from "@emotion/core"
import { sizes, desktopMediaQuery, colors } from "../../styles/constants"
import { LanguageCtx, defaultLang } from "../../context"
import { Modal } from "../Modal"
import PWithBr from "../PWithBr"

function AcceptTerms({ state, onChange }) {
  const { acceptTerms, termsAndConditions, termsAndCondition } = useContext(
    TextContentCtx
  )
  const { lang } = useContext(LanguageCtx)

  const [isTermsOpen, setIsTermsOpen] = useState(false)

  const handleTermsClick = () => {
    setIsTermsOpen(true)
  }
  const handleOnTermsClose = () => {
    setIsTermsOpen(false)
  }

  return (
    <div
      css={
        state.isError && !state.isAccepted
          ? [styles.container, styles.error]
          : styles.container
      }
    >
      <Icons
        type={
          state.isAccepted ? icon.CHECKBOX_CHECKED : icon.CHECKBOX_UNCHECKED
        }
        css={styles.checkbox}
        color={
          state.isError && !state.isAccepted ? colors.red400 : colors.primary
        }
        onClick={onChange}
      />
      <span css={styles.acceptText} onClick={onChange}>
        {acceptTerms}
        &nbsp;
        <span css={styles.termsLink} onClick={handleTermsClick}>
          {lang === defaultLang ? `${termsAndConditions}` : termsAndConditions}
        </span>
      </span>
      <Modal
        title={termsAndConditions}
        isOpen={isTermsOpen}
        onClose={handleOnTermsClose}
        content={<PWithBr>{termsAndCondition}</PWithBr>}
      />
    </div>
  )
}

export default AcceptTerms

const styles = {
  container: css`
    display: flex;
    align-items: center;
    padding: 0 ${sizes.smallScale.m};
    user-select: none;
    ${desktopMediaQuery} {
      padding: 0 ${sizes.smallScale.xl};
    }
  `,
  error: css`
    & * {
      color: ${colors.red400};
    }
  `,
  checkbox: css`
    padding-right: ${sizes.smallScale.s};
    user-select: none;
    &:hover {
      cursor: pointer;
    }
  `,
  acceptText: css`
    text-decoration: underline;
    user-select: none;
    &:hover {
      cursor: pointer;
    }
  `,
  termsLink: css`
    color: ${colors.primary};
    text-decoration: underline;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  `,
}
