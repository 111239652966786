import React from "react"
import { css } from "@emotion/core"
import {
    colors,
    desktopMediaQuery,
    fontSizes,
    sizes,
    shadow,
} from "../../styles/constants"
import { useContext } from "react"
import { TextContentCtx } from "../../templates/booking-page"

function NotesSection({ onChange }) {
    const { notes } = useContext(TextContentCtx)

    return (
        <div className="field" css={styles.container}>
            <label className="label">{notes}</label>
            <div className="control">
                <textarea
                    name="notes"
                    id="notes"
                    required={false}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

export default NotesSection

const styles = {
    container: css`
        padding: 2rem 1rem;
        & textarea {
            font-size: ${fontSizes.default};
            padding: ${sizes.smallScale.s};
            width: 100%;
            border: 0px;
            height: ${sizes.smallScale.xxxl};
            border-radius: 4px;
            background: ${colors.coldGray};
            border-radius: 0px;
            outline: none;
            resize: none;
            ${shadow.inputField};
        }
        ${desktopMediaQuery} {
            padding: 2rem;
        }
    `,
}
