import React from "react"
import styles from "../styles"

function Header({ locale }) {
    const weekdays = [...Array(7).keys()].map(i =>
        locale.localize.day(i, { width: "abbreviated" })
    )
    const { weekStartsOn } = locale.options
    for (let i = 0; i < weekStartsOn; i++) {
        const dayTemp = weekdays.shift()
        weekdays.push(dayTemp)
    }
    return (
        <ul className="weekdays" css={styles.weekdays}>
            {weekdays.map(day => (
                <li key={day}>{day}</li>
            ))}
        </ul>
    )
}

export default Header
