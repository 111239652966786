import React from "react"
import InputField from "./InputField"
import { css } from "@emotion/core"
import { useState } from "react"
import { findPromoCode } from "../../services/promoCodes"
import { SmallButton } from "../Button/Button"
import Icons, { icon } from "../Icons"
import { colors, sizes } from "../../styles/constants"
import { useContext } from "react"
import { TextContentCtx } from "../../templates/booking-page"

function PromoCode({ className, onDiscountChange }) {
    const {
        booking: { promoUse, promoTitle },
    } = useContext(TextContentCtx)
    const [code, setCode] = useState("")
    const [promoDiscount, setPromoDiscount] = useState(0)
    const [fetchingState, setFetchingState] = useState({
        isLoading: false,
        isError: false,
    })

    const handleUseClick = async () => {
        try {
            setFetchingState({
                isError: false,
                isLoading: true,
            })
            const response = await findPromoCode(code)
            onDiscountChange(response.data.value, code)
            setPromoDiscount(response.data.value)
            setFetchingState({
                isError: false,
                isLoading: false,
            })
        } catch (error) {
            setFetchingState({
                isError: true,
                isLoading: false,
            })
        }
    }

    return (
        <div className={className} css={styles.container}>
            <div css={styles.inputWithInfo}>
                <InputField
                    name="promoCode"
                    required={false}
                    onChange={event =>
                        setCode(event.target.value.toUpperCase())
                    }
                    label={promoTitle}
                    value={code}
                    disabled={promoDiscount !== 0}
                    css={styles.input}
                    isError={fetchingState.isError}
                />
            </div>
            <div css={styles.buttonContainer}>
                <SmallButton
                    onClick={handleUseClick}
                    disabled={promoDiscount !== 0 || fetchingState.isLoading}
                    css={styles.useButton}
                >
                    {promoDiscount !== 0 ? (
                        <Icons type={icon.CHECK} color={colors.green} />
                    ) : fetchingState.isLoading ? (
                        `${promoUse.toUpperCase()}...`
                    ) : (
                        promoUse.toUpperCase()
                    )}
                </SmallButton>
            </div>
        </div>
    )
}

export default PromoCode

const styles = {
    container: css`
        width: 100%;
    `,
    inputWithInfo: css`
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    `,
    input: css`
        width: 40%;
    `,
    useButton: css`
        margin-top: ${sizes.smallScale.m};
    `,
    buttonContainer: css`
        min-height: ${sizes.smallScale.xxxl};
    `,
}
