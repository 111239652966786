//TODO itt emgvaltoztatni az ar kalkulaciot
export const calculatePrice = (
    numberOfNights,
    perNightPrice,
    numberOfPersons,
    perPerson,
    amountOfFreePerson = 4,
    discount = 0,
    couplePrice = null,
    tax
) => {
    const nights = numberOfNights
    const basePerNightPrice =
        numberOfPersons <= 2 && couplePrice
            ? nights * couplePrice
            : nights * perNightPrice
    let perPersonExtraPrice = 0
    if (numberOfPersons && numberOfPersons > amountOfFreePerson) {
        perPersonExtraPrice =
            (numberOfPersons - amountOfFreePerson) * perPerson * nights
    }
    let sum = basePerNightPrice + perPersonExtraPrice
    if (discount !== 0) {
        if (discount < 1) {
            // percentage discount
            sum = sum * (1 - discount)
        } else {
            // fixed price discount
            sum = sum - discount
        }
    }
    sum = sum + nights * tax * numberOfPersons
    return sum > 0 ? Math.round(sum) : 0
}
